import React from "react";
import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer";

export const PageContainer = ({ children }) => {
  const headerRef = React.useRef(null);
  const footerRef = React.useRef(null);
  const [headerHeight, setHeaderHeight] = React.useState(0);
  const [footerHeight, setFooterHeight] = React.useState(0);

  React.useEffect(() => {
    if (headerRef.current && footerRef) {
      setHeaderHeight(headerRef.current.clientHeight);
      setFooterHeight(footerRef.current.clientHeight);
    }
  }, []);

  return (
    <div>
      <Header cRef={headerRef} />
      <div
        className="w-full flex flex-col"
        style={{
          minHeight: `calc(100vh - ${headerHeight}px - ${footerHeight}px)`,
        }}
      >
        {children}
      </div>
      <Footer cRef={footerRef} />
    </div>
  );
};
