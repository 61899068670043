import React from "react";

export const LayoutContainer = ({
  cRef,
  containerClassName,
  customStyle,
  // className,
  children,
}) => {
  return (
    <div
      ref={cRef}
      className={containerClassName}
      style={{
        display: "relative",
      }}
    >
      <div
      // className={`w-full sm:max-w-[540px] md:max-w-[720px] lg:max-w-[960px] max-w-7xl mx-auto px-4 ${className}`}
      // style={[
      //   {
      //     width: "100%",
      //     maxWidth: 1200,
      //     margin: "0 auto",
      //     paddingRight: 16,
      //     paddingLeft: 16,
      //   },
      //   customStyle,
      // ]}
      >
        {children}
      </div>
    </div>
  );
};
