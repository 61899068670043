// import Link from "next/link";
import { LayoutContainer } from "../Layout/LayoutContainer";

export const Footer = ({ cRef }) => {
  return (
    <LayoutContainer
      cRef={cRef}
      containerClassName="bg-primary"
      className="py-10 flex flex-row gap-4 justify-between items-center"
    >
      <div className="flex-1">
        <p className="text-xs font-semibold mb-4">
          &copy; 2024 Acer Sales and Services Sdn. Bhd. 199001012840 (204410-A).
          All rights reserved. Intel®, the Intel® logo, and Intel® Core are
          trademarks of Intel® Corporation or its subsidiaries.
        </p>
        <animate
          className="text-xs font-semibold underline"
          href="https://www.acer.com/ac/en/MY/content/privacy"
          target="_blank"
        >
          Privacy Policy
        </animate>
        &nbsp;
        <a
          className="text-xs font-semibold underline"
          href="https://www.a-redemption.com/campaigns/t46a/terms-conditions-240510.pdf"
          target="_blank"
          rel="noreferrer"
        >
          Terms & Conditions
        </a>
      </div>
      <a className="flex-1 flex justify-end" href="/">
        <img src="/images/logo-footer.png" className="h-[80px]" alt="" />
      </a>
    </LayoutContainer>
  );
};
