import { getPath } from "../../Utils/Helpers";
// import Image from "next/image";
// import Link from "next/link";
import { LayoutContainer } from "../Layout/LayoutContainer";
import { FaBars } from "react-icons/fa6";

export const Header = ({ cRef }) => {
  const menuList = [
    {
      path: getPath("/"),
      title: "Home",
    },
    {
      path: getPath("/products"),
      title: "Products",
    },
    {
      path: getPath("/guide"),
      title: "Buyer's Guide",
    },
    {
      path: getPath("/participate"),
      title: "Redeem",
    },
    {
      path: getPath("/track"),
      title: "Track & Trace",
    },
    {
      path: getPath("/stores"),
      title: "Stores",
    },
    {
      path: getPath("/contact"),
      title: "Contact Us",
    },
  ];

  return (
    <LayoutContainer
      cRef={cRef}
      containerClassName="bg-primary"
      // className="py-4 lg:py-0 flex flex-row gap-4 justify-between items-center"
      // customStyle={{ padding: 16 }}
    >
      <a href="/">
        <img
          src="/images/logo-white.png"
          // width={195}
          // height={80}
          className="w-auto max-h-[50px]"
          // priority
          alt=""
        />
      </a>

      {/* Mobile Menu */}
      <div className="lg:hidden">
        <FaBars className="text-xl text-white" />
      </div>

      {/* Desktop Menu */}
      <div className="hidden lg:flex flex-row">
        {menuList?.map((item, index) => (
          <a
            key={index}
            className="py-6 px-4 text-base font-medium text-white transition ease-in-out duration-150 hover:border-b-4 hover:border-b-white "
            href={item.path}
          >
            {item.title}
          </a>
        ))}
      </div>
    </LayoutContainer>
  );
};
