import { LayoutContainer } from "../Components/Layout/LayoutContainer";
import { PageContainer } from "../Components/Layout/PageContainer";
import Slider from "react-slick";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";

export default function Home() {
  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <FaChevronRight
        className={`text-xl text-white ${className}`}
        style={{ ...style }}
        onClick={onClick}
      />
    );
  }

  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <FaChevronLeft
        className={`text-xl text-white ${className}`}
        style={{ ...style }}
        onClick={onClick}
      />
    );
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplaySpeed: 5000,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    // appendDots: (dots) => (
    //   <div
    //     style={{
    //       borderRadius: "10px",
    //       padding: "10px",
    //     }}
    //   >
    //     <ul style={{ margin: "0px" }}> {dots} </ul>
    //   </div>
    // ),
  };

  return (
    <PageContainer>
      <Slider className="bg-[#033B29]" {...settings}>
        <div className="relative aspect-bannerDesktop">
          <img src="/images/banner-lg-1.jpg" alt="" />
        </div>
        <div className="relative aspect-bannerDesktop">
          <img src="/images/banner-lg-2.jpg" alt="" />
        </div>
      </Slider>
      <LayoutContainer
        containerClassName="bg-[#033B29] flex-1"
        className="py-32 flex flex-wrap justify-center items-center"
      >
        <div className="w-full lg:w-1/3"></div>
        <div className="w-full lg:w-1/12"></div>
        <div className="w-full lg:w-7/12"></div>
      </LayoutContainer>
    </PageContainer>
  );
}
